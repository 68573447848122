<template>
  <!-- eslint-disable vue/no-unused-refs MIXIN -->
  <div id="logo-slider-flickity-0"
       ref="flickityDiv"
       class="bx-slider bx-slider--logo-slider">
    <div v-for="(item, index) in sliderSettings.logoSlider.items"
         :id="`bx-js-logo-slider-cell-${index}`"
         :key="index"
         class="bx-slider__cell">
      <a :href="item.url"
         :title="item.name"
         tabindex="-1">
        <img :data-flickity-lazyload-src="iconPath + item.src"
             :src="sliderSettings.logoSlider.imageConfig.placeholder"
             :alt="item.name"
             :width="sliderSettings.logoSlider.imageConfig.width"
             :height="sliderSettings.logoSlider.imageConfig.height">
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import { usePageStore } from '../../stores/page'
import { useConfigStore } from '../../stores/config'
import flickitySlider from '../../mixins/flickity-slider'

export default {
  name: 'LogoSlider',
  mixins: [flickitySlider],
  props: {
    sliderOptions: {
      type: Object,
      default: () => { return {} }
    }
  },
  computed: {
    // Some of these store props are also or only used by mixins
    ...mapState(usePageStore, ['brandFromStore', 'viewportMetaModified']),
    ...mapState(useConfigStore, ['rsConfig']),
    iconPath () {
      return `/images/${this.brandFromStore}/logo-slider/`
    }
  },
  async mounted () {
    await this.initFlickitySlider(this.sliderOptions)
  }
}
</script>
