<template>
  <div class="bx-footer__abo-teaser">
    <div class="bx-footer__abo-teaser-inner-wrapper">
      <span class="bx-footer__abo-teaser-headline">
        Unterhaltung für zu Hause:
      </span>
    </div>
    <div class="bx-footer__abo-teaser-image-wrapper">
      <a v-for="magazine in magazines"
         :key="magazine"
         target="_blank"
         href="https://www.bauer-plus.de/wunderweib-banner"
         class="bx-footer__abo-teaser-image">
        <img :src="`https://cdn.xceler8.io/cover/${magazine}.jpg`"
             :alt="`${magazine} Cover`"
             width="100"
             height="133"
             loading="lazy">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      magazines: ['allesfuerdiefrau', 'laura', 'neuepost', 'tina', 'bella', 'wohnidee']
    }
  }
}
</script>
