<template>
  <div>
    <a class="bx-header__logo"
       title="www.wunderweib.de"
       href="/">
      <svg id="logo-claimed"
           class="bx-header__logo-claimed"
           xmlns="http://www.w3.org/2000/svg"
           xmlns:xlink="http://www.w3.org/1999/xlink"
           width="2600.65px"
           height="505.04px"
           viewBox="0 0 2600.65 505.04">

        <defs>
          <clipPath id="clip-path">
            <path class="cls-1"
                  d="M2577.85,480.17c2.77-.51,12.75-7.37,13.84-9.39a6.88,6.88,0,0,0-1.69-8.91c-5.1-4.72-11.89-2.1-15.85,6.29-2.83,5.24-2.83,7.86,0,10A7.79,7.79,0,0,0,2577.85,480.17Z" />
          </clipPath>
        </defs>
        <title>Wunderweib - wunderbar weiblich</title>
        <path class="logo-cl"
              d="M1128.84,455.45c1-6.23,2.44-11.67,2.09-11.67,0,0-6,8.19-11.52,15-15.07,18.63-22.68,23.09-28.23,23.09-3.12,0-6.17-1.65-7.09-4.59-1.54-4.86-1.33-16.4-.23-25.48,1-8.31,2.35-15.64,3.29-20.68,1.23-6.64,6-8.54,8-9,1.46-.36,4.61.33,5.31,2.87.66,2.39.12,5.46-.49,9.75-1.47,10.35-4.88,25.89-5.15,28-.38,3-1.36,6.77,1.51,6.91,3.49.17,11.63-8.42,22.65-22.46,8.52-10.86,10.85-15.54,14-15.89,2.76-.34,4,0,5.51,2.66,1.18,2-1,9.46-2.2,18.87-1.56,11.63-2,14.94-.34,17.1a4.62,4.62,0,0,0,4.33,1.4c3.8-.69,10.34-5.93,17.46-14.95,7.52-9.52,17-22.1,18.14-23.08,4-3.31,5.66-1.88,9.9,2,3.94,3.6,7.16,5.13,11.46,4.16,9-2,12.67-6.27,19.9-15.1.32-.4,3.68-1.08,3.68-1.08l1.27-.41a1.51,1.51,0,0,1,2.07,1.73c0,.35-.34.69-.69,1s-1.13.54-1.73,1c-7.63,6.35-16.36,17.11-25.14,18.19-9.56,1.17-17.28-5.41-17.28-5.41s-17.88,30.8-32.44,39.8c-6.84,4.23-15.53,3.33-18.21-3.13S1128,460.85,1128.84,455.45Z" />
        <path class="logo-cl"
              d="M1201.56,467.54c.75-12,4.71-19.84,11.28-35.05,3.8-9,5.18-10.37,9.68-10.37a5.77,5.77,0,0,1,5.6,3.72c1.31,3.32-.89,8.84-2.14,12.18-4.11,11-15.12,30.26-14.43,33.72a2.84,2.84,0,0,0,1.39,2.07c1.38,1,9.26-3.68,15.35-8.7,13.65-11.27,22.11-21.65,29.71-27.87,8.65-6.91,10-7.42,13.09-4.31,2.77,2.76,2.2,7-2.63,22.83-1,3.11-5.44,16.45-4.83,19.52.29,1.44,7.93-4.95,17.17-12,11.55-8.82,22.33-19.83,31-25.36,1.73-1,3.46-.7,2.77,1.37a14.88,14.88,0,0,1-2.35,2.47c-2.76,1.73,1.89-.32-5,4.87-19,15.91-30.73,27.2-37.48,31.83-7.78,5.34-11.88,3.89-12.9-1.11-1.71-8.35,4.94-28.51,6.67-33.35.69-2.07,1-3.8.69-3.8,0,0-14.28,14-18.62,18-8,7.27-24.49,24.87-32.85,23.66C1202.58,480.38,1201.3,471.68,1201.56,467.54Z" />
        <path class="logo-cl"
              d="M1310.76,430.16c2.77-5.18,4.5-7.26,6.92-8,4.84-1.38,8.57,2.49,7.53,7.67,0,1-2.07,6.23-4.84,11.06l-13.61,29.19s16.85-22.06,19.2-24.61c6.1-6.61,8.66-10.61,13.26-13.14,3-1.64,5.62-1.58,7.7-.2,2.42,1.73,4.93,4.44,6,11,1.38,7.6,1.21,10.58,2.13,19,.31,2.84,2.82,13.73,6.64,13.81,3.44.08,11.1-5.94,19.11-12.27,3.69-2.91,1.56-2.94,4.69-5.05a1.39,1.39,0,0,1,1.6.16l0,.35c.25.72,0,3.4-.69,4-5,4.39-8,7.82-14.43,12.84s-8.74,7.4-15,4.71c-4-1.75-6.94-9.38-8.88-21.76-.93-5.93-1.81-19.5-5.79-19.17-4.16.35-15,17-23.73,27.79-12.15,15.07-14.86,14.29-19,14.29s-5.69-4.56-5-9.4c.69-3.46,9.33-26.79,11.06-30.94Z" />
        <path class="logo-cl"
              d="M1514.87,468.59c12.64-8.88,22.36-22.22,33.42-32.93.69-.35,1-.69,1.73-1a5,5,0,0,1,2.07.35l-.69.69c.69.34.69,1,.69,1.73a4.08,4.08,0,0,1-1.72,1.72c-3.81,4.15-7.61,8-11.41,12.1-9.34,9.68-17.47,20.74-30.66,27.41-5.11,2.58-14.06,4.84-19.59,1.73-3.8-2.08-5.9-5.53-6.91-9.68-1.11-4.54-.08-13.34.58-17.73.58-3.9,2.76-7.6,4.49-11.41,3.11-5.87,4.15-7.6,8.3-11.75,2.76-2.77,6.22-5.53,7.95-6.22,9.33-4.5,15.9,2.07,12.44,12.44-1.38,3.81-6.56,11.41-10.37,14.87-3.11,3.11-8.29,5.88-10.71,5.88-1.73,0-2.66,1.27-3.28,2.65-1.18,2.63-1.25,8.48-.42,11.61a5.35,5.35,0,0,0,3.46,4.05c3.5,1.45,10-.5,13.37-2.32C1509.45,471.75,1513.16,469.79,1514.87,468.59ZM1494,450.14c-.79,2.39,1.19,1.2,4.77-2.78s6.36-9.14,7.16-12.72c.4-2.39.4-2.39-2.39.79-2.38,2.39-8.35,11.53-9.54,14.71Z" />
        <path class="logo-cl"
              d="M1623.12,445.82c-4.9,2.47-10.1,4.2-13.32,4.2-5.87,0-9.33-2.42-14.17-11.07-3.8-6.91-5.88-8.64-10-8.64-5.18,0-8.29,2.07-21.78,14.17a131.32,131.32,0,0,1-10.37,9.34l-2.42,2.07,2.42-3.8c2.77-5.18,9.5-19.6,10.54-22.36,1-4.5-1-7.61-4.84-7.61a6.71,6.71,0,0,0-3.46,1c-.93.57-3.46,4.15-5.88,8.29l-4.31,7.85-2.42,4.49s-1.73,4.15-3.8,8.64c-5.53,12.1-10,23.86-10,25.59,0,2.07,1.73,3.8,4.5,3.8,2.42,0,3.8-1.73,14.52-13.83,14.86-17.28,28.69-30.42,34.57-32.15,3.45-1,4.49-.34,8.3,5.88,7.6,11.41,14.17,14.52,24.89,11.06,1.63-.58,4-1.6,6.64-2.87A27.81,27.81,0,0,1,1623.12,445.82Z" />
        <path class="logo-cl"
              d="M1708.84,477.21c-1.89-2.84-2.36-10.39-1.67-15.57l.35-4.15-3.8,3.11c-7.26,6.57-22.13,18.32-24.89,19.7-3.46,2.08-5.88,2.08-8.3.35-5.53-3.46-7.83-12.42-1.73-24.89,5.26-10.75,10.91-17.64,18.51-25.24,9.34-9.34,18.33-11.07,24.55-4.5,3.46,3.46,5.18,7.61,5.18,14.52l-.19.7,2.08.34a11,11,0,0,1,3.46,1.73c2.07,2.08,2.07,5.88-.35,11.76s-5.53,15.21-5.53,16.94c0,7.95,11.06,3.45,30.42-12.1,5.19-4.15,10-8.3,17.63-15.56a1.39,1.39,0,0,1,2.08.69l-1,.35c.69.69.34,1,0,1.73-28.35,26.27-38.92,35.19-47.21,34.66C1711.51,481.33,1710.44,479.61,1708.84,477.21Zm-2.7-25.95a4.63,4.63,0,0,0,1.18-3.57c-1-6.92-3.1-13.57-4.48-15-1-1-2.33-1.42-5.48,1.65-6.83,6.66-22.63,28.54-21.3,37.63.2,1.37.38,2.38,3.84.65,6.22-3.8,22.09-17.25,26.24-21.4Z" />
        <path class="logo-cl"
              d="M1856.52,440.19c-7.61,4.84-18.67,10.37-23.51,12.1-10.72,3.46-17.29.35-24.89-11.06-3.8-6.23-4.84-6.92-8.3-5.88-5.88,1.73-19.7,14.86-34.57,32.15-10.72,12.1-12.1,14.41-14.52,14.41-2.77,0-4.49-1.73-4.49-3.81,0-1.73,4.49-14.06,10-26.16,2.08-4.49,3.8-8.64,3.8-8.64l2.42-4.49,4.15-7.26c2.42-4.15,4.84-7.95,5.88-8.3a5.16,5.16,0,0,1,3.46-1c3.8,0,5.87,3.11,4.84,7.61-1,2.76-7.61,16.59-10.37,21.78l-2.42,3.8,2.42-2.07a135.11,135.11,0,0,0,10.37-9.34c13.48-12.1,16.59-14.17,21.78-14.17,4.15,0,6.22,1.73,10,8.64,4.84,8.64,8.3,11.06,14.18,11.06,5.18,0,15.55-4.49,21.43-9.33,1-.69,3.11-2.08,5.53-3.8,1.73-1,3.46-.35,3.81,1.38A2.7,2.7,0,0,1,1856.52,440.19Z" />
        <path class="logo-cl"
              d="M2105.65,422.1a4.76,4.76,0,0,0,2.26,1.35c1.69-.34,7.79-4.86,8.46-6.19a4.8,4.8,0,0,0-1-5.88c-3.11-3.11-7.26-1.38-9.68,4.15C2103.92,419,2103.92,420.71,2105.65,422.1Z" />
        <path class="logo-cl"
              d="M2160.92,452.28c-13.72,7-27.54,13.8-33.49,16.49-12.1,5.53-20.4,7.26-25.24,5.53-5.53-2.07-4.84-7.26,4.5-25.58,3.8-8,4.15-10.37.69-13.49-4.5-3.8-8.64-2.07-12.79,5.88-1.73,3.11-3.81,10.37-3.81,10.37s-.34,1.39-.69,2.42a48.4,48.4,0,0,0-.69,8.65c0,5.87,0,7.25,1.38,10,3.46,7.61,11.07,10,23.17,6.92,8-2.08,16.59-5.88,35.61-16.6,3.33-1.79,7.32-4,11.42-6.2A6.37,6.37,0,0,1,2160.92,452.28Z" />
        <path class="logo-cl"
              d="M2220.35,460.36c7.14-39.23,52-97,74.52-129.92,8.24-12,14.67-15.86,18.59-10.56,1.34,1.81.84,5.78-2.38,9.74-6.79,8.25-19.07,25.25-24.43,32.84-24.66,34.65-46.93,65-58.66,94.94-3.68,9.38-4.58,13.39-2.8,17.4,1.84,4.17,12.61-.14,22.29-5.67,2.76-1.72,19.36-12.1,36.64-23.5,1.07-.69,4.49-2.44,4.49-2.44a1.53,1.53,0,0,1,2.42,1l-.35.35c.35.69,0,1-.69,1.73-2.76,1.73-2.07,1.38-6.22,4.15-38.37,25.92-40.21,26.16-49.81,29.82C2228,482.54,2215.74,485.69,2220.35,460.36Z" />
        <path class="logo-cl"
              d="M2347.77,453.77c0,.69-.69,1-1,1.38l-4,2.18-8.36,5.56c-19,10.72-27.66,14.52-35.61,16.6-12.1,3.11-19.71.69-23.16-6.92-1.39-2.77-1.39-4.15-1.39-10a48.4,48.4,0,0,1,.69-8.65c.35-1,.7-2.42.7-2.42s2.07-7.26,3.8-10.37c4.15-7.95,8.3-9.68,12.79-5.88,3.46,3.12,3.11,5.54-.69,13.49-9.34,18.32-10,23.51-4.5,25.58,4.84,1.73,13.14,0,25.24-5.53,10.72-4.84,18.23-8.44,33.44-16.73Zm-54.87-30.29a4.39,4.39,0,0,1-2.42-1.38c-1.73-1.39-1.73-3.11,0-6.57,2.42-5.53,6.57-7.26,9.68-4.15a4.78,4.78,0,0,1,1,5.88c-.69,1.38-7.26,6.22-8.64,6.22Z" />
        <path class="logo-cl"
              d="M2359.45,424.48c3.29-2.23,9-2.71,11.73-2,4.15,1.38,1.73,11.75-1.38,12.79-5.19,1.73-25.28,18-25.28,34.26,0,3.46,1.74,4.48,2.4,4.89,2.94,1.82,10-.39,19.73-5.92,2.76-1.73,19.36-12.1,36.64-23.51,5.88-3.8,8-4.84,12.45-7.61a1.53,1.53,0,0,1,2.42,1l-.35.35c.35.69,0,1-.69,1.73-2.76,1.72-2.07,1.38-6.22,4.14-38.37,25.93-44.33,30.87-53,34.33-7.95,3.11-14.17,4.1-18,.65-6.57-5.54-9.64-18.49-.24-33.6C2343.86,439.33,2353,428.91,2359.45,424.48Z" />
        <path class="logo-cl"
              d="M2542.94,461.2c-9.66,5.31-25.4,12.95-31.44,15.35s-14.94,5.91-19.75,5.36c-7.44-.86-11.8-9.29-17.42-28.33s-14.42-24.69-32.27-10.53c-8.39,6.66-16.11,15.31-31,30.87-4.84,5.18-7.6,7.6-8.64,7.95-1.73.34-5.18-1.73-5.88-3.46-1.38-2.77,7.4-25.37,18.8-48.53l4.5-9,5.18-10.37c15.91-30.77,32.75-58.47,38.28-67.81,5.87-9.33,12.57-19.37,16-22.48,4.15-3.46,6.22-3.8,8.64-1,3.8,3.46,1.38,8.64-14.87,33.53-26.27,40.11-43.37,63.12-59.62,96a77.29,77.29,0,0,0-4.14,9.33c.34.35,2.42-1.73,4.84-4.49,34.78-33.7,45.6-34.93,56-28,11.16,7.45,11.25,35.81,17.35,46.3,2.34,4,5.29,8.07,16.7,3.92,6-2.17,26.34-11.17,38.52-17.68a31.07,31.07,0,0,0,3.58-1.85l1.07-.56a1.26,1.26,0,0,1,1.51,2C2548.13,458.72,2544.52,460.33,2542.94,461.2Z" />
        <path class="logo-cl"
              d="M1612,490.27c0,.35,1.39-.34,2.42-1s3.11-2.08,4.5-3.12c3.45-2.42,13.83-13.13,18-18.32h0c3.8-4.84,7-9.18,10.68-14.82,1.72-2.63,4.21-7.54,5.57-10.08,2.16-4,4.7-9.64,2.64-11.52-1.45-1.33-9.7,3.19-15.23,7.69-12.1,9-23.35,19-30.61,32.84-1.38,2.76-5.5,10.9-7.27,9.88-1.08-.63-1.39-1.73-1-5.19.69-8.3,7.6-26.43,22.12-55.12l8.3-16.6,3.45-6.22,3.46-6.22c4.49-9.34,17.28-32.5,22.12-40.8,8.3-14.52,20.75-31.8,24.2-33.53,3.81-2.08,6.92,0,6.92,4.15a7,7,0,0,1-1.38,4.84c-1.39,3.11-9,14.52-15.56,24.89-17.29,25.23-34.92,54.27-47.71,77.78-4.84,8.3-9.68,18-9.33,18a45.41,45.41,0,0,1,3.11-4.15c10.41-12.45,17.89-15.64,25.63-19.16,7.11-3.23,13-3,16.41.45,2.42,2.42,4,4.22,3.45,9.68a22.48,22.48,0,0,1-1.59,5.92c-.54,1.24-1.84,3.62-2.42,4.84-2.9,6.16-8.29,14.52-13.13,21.09-11.07,13.83-24.2,24.89-33.19,28-6.92,2.77-9.68-.73-8.3-7.26a17.57,17.57,0,0,1,5.4-8.36c2.69-2.33,4.76-.51,4.18-.18C1613.6,481,1611.79,487.58,1612,490.27Z" />
        <path class="logo-cl"
              d="M2159.08,490.28c0,.34,1.38-.35,2.42-1s3.11-2.07,4.49-3.11c3.46-2.42,13.83-13.14,18-18.32h0c3.8-4.84,7-9.19,10.68-14.82,1.73-2.63,4.22-7.55,5.58-10.08,2.16-4,4.69-9.64,2.64-11.53-1.45-1.33-9.7,3.2-15.23,7.69-12.1,9-23.35,19-30.61,32.84-1.39,2.77-5.5,10.91-7.28,9.88-1.08-.62-1.38-1.73-1-5.18.69-8.3,7.59-26.43,22.11-55.13l8.3-16.59,3.46-6.23,3.45-6.22c4.5-9.33,17.29-32.5,22.13-40.79,8.3-14.52,20.74-31.81,24.2-33.54,3.8-2.07,6.91,0,6.91,4.15a7,7,0,0,1-1.38,4.84c-1.38,3.11-9,14.52-15.56,24.89-17.28,25.24-34.91,54.28-47.7,77.79-4.84,8.3-9.68,18-9.34,18a47.82,47.82,0,0,1,3.11-4.15c10.41-12.46,17.9-15.64,25.64-19.16,7.1-3.24,12.94-3,16.4.45,2.42,2.42,4,4.22,3.46,9.68a23.5,23.5,0,0,1-1.59,5.92c-.55,1.23-1.84,3.61-2.42,4.84-2.91,6.15-8.3,14.52-13.14,21.08-11.06,13.83-24.2,24.9-33.19,28-6.91,2.76-9.68-.73-8.29-7.26a17.54,17.54,0,0,1,5.39-8.36c2.69-2.33,4.76-.51,4.19-.19C2160.68,481,2158.87,487.58,2159.08,490.28Z" />
        <path class="logo-cl"
              d="M2064.9,468.6c12.64-8.89,22.36-22.22,33.42-32.94.69-.34,1-.69,1.73-1a5.07,5.07,0,0,1,2.07.35l-.69.69c.69.35.69,1,.69,1.73a4.18,4.18,0,0,1-1.73,1.73c-3.8,4.15-7.6,7.95-11.41,12.1-9.33,9.68-17.47,20.73-30.65,27.4-5.11,2.58-14.06,4.84-19.59,1.73-3.81-2.07-5.9-5.53-6.92-9.68-1.11-4.53-.08-13.34.58-17.72.59-3.9,2.77-7.61,4.5-11.41,3.11-5.88,4.15-7.61,8.29-11.76,2.77-2.76,6.23-5.53,8-6.22,9.33-4.49,15.9,2.08,12.44,12.45-1.38,3.8-6.57,11.41-10.37,14.86-3.11,3.11-8.3,5.88-10.72,5.88-1.73,0-2.65,1.27-3.27,2.65-1.19,2.63-1.25,8.48-.42,11.62a5.36,5.36,0,0,0,3.45,4c3.51,1.46,10-.49,13.38-2.32C2059.48,471.76,2063.19,469.8,2064.9,468.6Zm-21.09-18.45c-.79,2.38,1.2,1.19,4.78-2.79s6.36-9.14,7.15-12.72c.4-2.38.4-2.38-2.38.8-2.39,2.38-8.35,11.53-9.55,14.71Z" />
        <path class="logo-cl"
              d="M1963.74,455.47c1-6.22,2.43-11.67,2.09-11.67,0,0-6,8.19-11.52,15.05-15.07,18.62-22.68,23.08-28.24,23.08-3.11,0-6.16-1.65-7.09-4.58-1.53-4.87-1.32-16.4-.23-25.48,1-8.32,2.36-15.64,3.29-20.69,1.23-6.63,6-8.54,8-9,1.46-.36,4.6.33,5.31,2.87s.11,5.46-.5,9.75c-1.47,10.35-4.88,25.9-5.14,28-.38,3-1.37,6.76,1.5,6.9,3.5.17,11.64-8.41,22.65-22.45,8.52-10.87,10.86-15.55,14-15.89,2.77-.35,4,0,5.52,2.66,1.17,2-.95,9.45-2.21,18.86-1.55,11.64-2,14.94-.33,17.1a4.62,4.62,0,0,0,4.32,1.41c3.8-.69,10.35-5.93,17.47-14.95,7.52-9.53,17-22.1,18.14-23.08,4-3.31,5.65-1.89,9.89,2,3.94,3.59,7.16,5.12,11.47,4.16,9-2,12.66-6.27,19.89-15.1.33-.4,3.68-1.08,3.68-1.08l1.27-.41a1.51,1.51,0,0,1,2.08,1.72c0,.35-.35.7-.69,1s-1.14.55-1.73,1c-7.64,6.35-16.37,17.12-25.15,18.2-9.55,1.17-17.27-5.41-17.27-5.41s-17.89,30.79-32.44,39.79c-6.84,4.23-15.53,3.34-18.21-3.13S1962.88,460.88,1963.74,455.47Z" />
        <path class="logo-cl"
              d="M1388.27,461.18c-2.27,3.95-5.08,10.18-2.64,11.52,3,1.66,13.15-5.1,18.4-9.92a147.08,147.08,0,0,0,16.43-17.07c6.33-7.6,24.26-31.74,36.08-50.81,10-16.19,27.73-50,38-66,6.43-10,7.26-10.37,9.68-10.72,4.5-.69,6.92,3.12,4.84,7.61-1,3.11-7.6,15.21-14.52,25.58h0c-11.41,16.94-29.73,50.94-42.52,81.71-11.76,28-16.9,43.13-11.73,48.35,13,13.13,38.1-28.11,48.81-44,1.39-2.07,4.9-4.67,5.59-2.25a5.82,5.82,0,0,1-.35,2.42s-10.86,17.36-15,22.58c-6.55,8.18-21.34,30.85-34,31.69-7,.46-14.33-4.66-13.92-14.92.81-20,9.79-42.25,9.79-42.25S1429,448.75,1422,457.1c-10.75,12.66-19.76,19-27.5,22.54-7.1,3.23-12.66,3-16.4-.45-2.53-2.31-4-4.22-3.46-9.68a25.82,25.82,0,0,1,1.59-5.92,72.19,72.19,0,0,1,9.72-16.68c11-14.44,21.56-20.52,30.54-23.63,6.92-2.77,11.57-1.25,8.64,4.14-.81,1.5-3.78,2.69-4.14,0,0-.34-1.38.35-2.42,1s-3.08,2.11-4.5,3.11C1404.46,438.34,1393.88,451.39,1388.27,461.18Z" />

        <path class="logo-char"
              d="M202,228.23l-48.74-123-55,124.16a3,3,0,0,1-5.54-.14L8.09,8.66h40.8l51,145.8,36.3-93-21-52.8h34.5l56.1,141.6,46.8-141.6h37.5L207.62,228.18A3,3,0,0,1,202,228.23Z" />
        <path class="logo-char"
              d="M369.29,170.66a48.75,48.75,0,0,0,18,17.25,49.7,49.7,0,0,0,24.9,6.45,52.68,52.68,0,0,0,25.65-6.3A47.52,47.52,0,0,0,456,171a44.68,44.68,0,0,0,6.6-23.7V7.46h34.5v140.7q0,23.4-11.25,41.55a77.9,77.9,0,0,1-30.75,28.35,95,95,0,0,1-85.65,0A77,77,0,0,1,339,189.71q-11.1-18.15-11.1-41.55V7.46h34.8v139.8A44.06,44.06,0,0,0,369.29,170.66Z" />
        <path class="logo-char"
              d="M746.39,7.46v226.2h-.9L588,81l.9,144.9h-35.4V0h1.8l156.9,154.5-.6-147Z" />
        <path class="logo-char"
              d="M806.68,7.46h62.11q41.09,0,68,16.2t39.14,41.7a123.39,123.39,0,0,1,12.3,54.3q0,31.8-14.85,56a100.85,100.85,0,0,1-39.75,37.2,115.18,115.18,0,0,1-54.29,13.05H806.68ZM873,192.26q23.38,0,41.25-8.7a64.71,64.71,0,0,0,27.89-25.65q10.05-16.95,10-40.65,0-25.8-10.94-43.05T913.33,48.86a80.18,80.18,0,0,0-35-8.1H841.79v151.5Z" />
        <path class="logo-char"
              d="M1179.88,7.46v33.6h-109.8v58.2h97.8v33.6h-97.8v59.4h114v33.6H1035V7.46Z" />
        <path class="logo-char"
              d="M1351.78,225.86l-49.2-75.9h-35.1v75.9h-35.1V7.46h61.5q36.6,0,58.65,18.6t22,51.6A82.94,82.94,0,0,1,1366,115q-8.55,17.25-26.85,26.55l50.2,79.7a3,3,0,0,1-2.54,4.6Zm-84.3-109.5h31.2q21,0,30.45-11.55a39.93,39.93,0,0,0,9.45-25.95,43.42,43.42,0,0,0-3.6-17,34.33,34.33,0,0,0-12.3-14.7q-8.7-6.15-22.8-6.15h-32.4Z" />
        <path class="logo-char"
              d="M1593.42,228.23l-48.74-123-55,124.16a3,3,0,0,1-5.54-.14L1399.48,8.66h40.8l51,145.8,36.3-93-21-52.8h34.5l56.1,141.6L1644,8.66h37.5L1599,228.18A3,3,0,0,1,1593.42,228.23Z" />
        <path class="logo-char"
              d="M1867.48,7.46v33.6h-109.8v58.2h97.8v33.6h-97.8v59.4h114v33.6h-149.1V7.46Z" />
        <path class="logo-char"
              d="M1955.08,7.46v218.4H1920V7.46Z" />
        <path class="logo-char"
              d="M2147.22,132.56a48.18,48.18,0,0,1,10.35,30.6q0,31.8-21.45,47.25t-52.95,15.45h-68.1V7.46h59.7q34.2,0,53.1,14t18.9,41q0,17.1-7.35,29.55a54.15,54.15,0,0,1-20.85,20Q2136.87,119.36,2147.22,132.56Zm-97-32.1h30.3q15,0,22.65-8.7t7.65-21.9q0-14.4-9.15-21.6t-25.35-7.2h-26.1Zm60.3,85.35q11.1-7.65,11.1-22.35,0-15.6-12.15-22.5t-30.15-6.9h-29.1v59.4h31.5Q2099.37,193.46,2110.47,185.81Z" />

        <g class="cls-5">
          <g class="cls-6">
            <rect class="logo-cl"
                  x="2551.4"
                  y="438.91"
                  width="61.88"
                  height="61.88" />
          </g>
        </g>

      </svg>
      <svg id="logo-small"
           class="bx-header__logo-small"
           xmlns="http://www.w3.org/2000/svg"
           xmlns:xlink="http://www.w3.org/1999/xlink"
           width="2193.93px"
           height="252.52px"
           viewBox="0 0 2193.93 252.52">
        <title>Wunderweib - wunderbar weiblich</title>
        <path class="logo-char"
              d="M202,228.23l-48.74-123-55,124.16a3,3,0,0,1-5.54-.14L8.09,8.66h40.8l51,145.8,36.3-93-21-52.8h34.5l56.1,141.6,46.8-141.6h37.5L207.62,228.18A3,3,0,0,1,202,228.23Z" />
        <path class="logo-char"
              d="M369.29,170.66a48.75,48.75,0,0,0,18,17.25,49.7,49.7,0,0,0,24.9,6.45,52.68,52.68,0,0,0,25.65-6.3A47.52,47.52,0,0,0,456,171a44.68,44.68,0,0,0,6.6-23.7V7.46h34.5v140.7q0,23.4-11.25,41.55a77.9,77.9,0,0,1-30.75,28.35,95,95,0,0,1-85.65,0A77,77,0,0,1,339,189.71q-11.1-18.15-11.1-41.55V7.46h34.8v139.8A44.06,44.06,0,0,0,369.29,170.66Z" />
        <path class="logo-char"
              d="M746.39,7.46v226.2h-.9L588,81l.9,144.9h-35.4V0h1.8l156.9,154.5-.6-147Z" />
        <path class="logo-char"
              d="M806.68,7.46h62.11q41.09,0,68,16.2t39.14,41.7a123.39,123.39,0,0,1,12.3,54.3q0,31.8-14.85,56a100.85,100.85,0,0,1-39.75,37.2,115.18,115.18,0,0,1-54.29,13.05H806.68ZM873,192.26q23.38,0,41.25-8.7a64.71,64.71,0,0,0,27.89-25.65q10.05-16.95,10-40.65,0-25.8-10.94-43.05T913.33,48.86a80.18,80.18,0,0,0-35-8.1H841.79v151.5Z" />
        <path class="logo-char"
              d="M1179.88,7.46v33.6h-109.8v58.2h97.8v33.6h-97.8v59.4h114v33.6H1035V7.46Z" />
        <path class="logo-char"
              d="M1351.78,225.86l-49.2-75.9h-35.1v75.9h-35.1V7.46h61.5q36.6,0,58.65,18.6t22,51.6A82.94,82.94,0,0,1,1366,115q-8.55,17.25-26.85,26.55l50.2,79.7a3,3,0,0,1-2.54,4.6Zm-84.3-109.5h31.2q21,0,30.45-11.55a39.93,39.93,0,0,0,9.45-25.95,43.42,43.42,0,0,0-3.6-17,34.33,34.33,0,0,0-12.3-14.7q-8.7-6.15-22.8-6.15h-32.4Z" />
        <path class="logo-char"
              d="M1593.42,228.23l-48.74-123-55,124.16a3,3,0,0,1-5.54-.14L1399.48,8.66h40.8l51,145.8,36.3-93-21-52.8h34.5l56.1,141.6L1644,8.66h37.5L1599,228.18A3,3,0,0,1,1593.42,228.23Z" />
        <path class="logo-char"
              d="M1867.48,7.46v33.6h-109.8v58.2h97.8v33.6h-97.8v59.4h114v33.6h-149.1V7.46Z" />
        <path class="logo-char"
              d="M1955.08,7.46v218.4H1920V7.46Z" />
        <path class="logo-char"
              d="M2147.22,132.56a48.18,48.18,0,0,1,10.35,30.6q0,31.8-21.45,47.25t-52.95,15.45h-68.1V7.46h59.7q34.2,0,53.1,14t18.9,41q0,17.1-7.35,29.55a54.15,54.15,0,0,1-20.85,20Q2136.87,119.36,2147.22,132.56Zm-97-32.1h30.3q15,0,22.65-8.7t7.65-21.9q0-14.4-9.15-21.6t-25.35-7.2h-26.1Zm60.3,85.35q11.1-7.65,11.1-22.35,0-15.6-12.15-22.5t-30.15-6.9h-29.1v59.4h31.5Q2099.37,193.46,2110.47,185.81Z" />
      </svg>
      <span>Wunderweib</span>
    </a>
  </div>
</template>

<script>
export default {}
</script>
