<template>
  <div class="bx-flooding bx-flooding--footer">
    <footer class="bx-footer bx-typo--footer">
      <LogoSlider :slider-options="sliderOptions" />
      <div class="bx-footer__wrapper">
        <div class="bx-grid">
          <SeoHeadline />
          <NewsletterSubscription usage="footer"
                                  headline="Abonniere unseren Newsletter:" />
          <SocialLinks menu-type="footer"
                       :brand="brand" />
          <FooterAboTeaser />
        </div>
      </div>
      <div class="bx-footer__menu">
        <div class="bx-grid">
          <MenuList :menu="footerData.menu"
                    menu-type="footer" />
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapState } from 'pinia'

import { useConfigStore } from '../../../stores/config'
import MenuList from '../../../components/menu/MenuList.vue'
import LogoSlider from '../../../components/page-footer/LogoSlider.vue'
import SeoHeadline from '../../../components/page-footer/SeoHeadline.vue'
import NewsletterSubscription from '../../../components/shared/NewsletterSubscription.vue'
import SocialLinks from '../../../components/shared/SocialLinks.vue'
import FooterAboTeaser from './FooterAboTeaser.vue'

export default {
  components: {
    MenuList,
    SeoHeadline,
    LogoSlider,
    SocialLinks,
    NewsletterSubscription,
    FooterAboTeaser
  },
  props: {
    footerData: {
      type: Object,
      default: () => { return {} }
    },
    brand: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState(useConfigStore, ['rsConfig']),
    sliderOptions () {
      return {
        initialIndex: Math.floor(Math.random() * (this.rsConfig.slider.logoSlider.items.length - 1)),
        wrapAround: true,
        prevNextButtons: false,
        groupCells: false,
        autoPlay: 1500,
        cellAlign: 'center',
        selectedAttraction: 0.0075,
        accessibility: false,
        lazyLoad: 25
      }
    }
  }
}
</script>
